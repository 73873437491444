.clear-button:hover  {
  background: rgba(167, 182, 194, 0) !important;
  color: #8A9BA8 !important;
}

.clear-button:active {
  background: rgba(115, 134, 148, 0);
}

.clear-button:focus {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}

.clear-button > .bp3-icon {
  color: #D8E1E8
}

.clear-button:hover > .bp3-icon {
  color: #8A9BA8
}

.account-button > .bp3-button-text {
  margin-right: 5px;
}

.account-button-divider {
  margin-top: 12px;
  margin-bottom: 8px;
}
