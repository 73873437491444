.overview-table {
    margin: 4px 0px 0px 0px;
}

.react-bootstrap-table {
    margin-right: 18px;
}

.error-toast {
    margin-top: 0;
    align-self: center;
}

.table-bordered td {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-bootstrap-table-page-btns-ul {
    margin-right: 18px;
    margin-bottom: 0;
}

.page-link {
    color: #282c34 !important;
    background-color: white !important;
    border-color: #dee2e6 !important;
    padding: 0.6rem;
}

.table-top-bar {
    padding-bottom: 12px;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.table-page-stats {
    padding-right: 8px;
    padding-left: 8px;
}

.table-search {
    width: 240px;
    margin-left: 10px;
    margin-right: 4px;
}

.table-page-controls {
    display: flex;
    align-items: center;
}

.table-search-bar {
    display: flex;
    align-items: center;
    flex-grow: 1;
}
